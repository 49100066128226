import deMessages from "./locales/de-DE.json";
import enGBMessages from "./locales/en-GB.json";
import enUSMessages from "./locales/en-US.json";

const dateTimeFormatDE = {
  long: {
    weekday: "long", // Display the full weekday name (e.g., "Wednesday")
    day: "numeric", // Display the day of the month (e.g., "30")
    month: "long", // Display the full month name (e.g., "August")
    year: "numeric" // Display the year (e.g., "2023")
  }
};

const dateTimeFormatUS = {
  long: {
    weekday: "long", // Display the full weekday name (e.g., "Wednesday")
    day: "numeric", // Display the day of the month (e.g., "30")
    month: "long", // Display the full month name (e.g., "August")
    year: "numeric" // Display the year (e.g., "2023")
  }
};

const dateTimeFormatGB = {
  long: {
    weekday: "long", // Display the full weekday name (e.g., "Wednesday")
    day: "numeric", // Display the day of the month (e.g., "30")
    month: "long", // Display the full month name (e.g., "August")
    year: "numeric" // Display the year (e.g., "2023")
  }
};

export default defineI18nConfig(() => ({
  fallbackLocale: "de",
  legacy: false,
  messages: {
    "de": deMessages,
    "en": enGBMessages,

    "de-DE": deMessages,
    "en-US": enUSMessages,
    "en-GB": enGBMessages
  },
  dateTimeFormats: {
    "de-DE": dateTimeFormatDE,
    "en-US": dateTimeFormatUS,
    "en-GB": dateTimeFormatGB
  }
}));
